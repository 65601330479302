import '../sass/main.scss';

import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel'; 
import LazyLoad from 'vanilla-lazyload';


var siteGlobal = {
  initReady: function () {
  },
  initLoad: function () {
  	this.lazyLoad();
    this.langsMenu();
    this.menuInit();
    this.homeCasiStudio();
    this.homeCollaborazioni();
    this.homeMetodoSlider();
    this.serviziSlider();
    this.altriServSlider();
  },
  initScroll: function(){
  },
  resize: function(){
    this.homeMetodoSlider();
    this.serviziSlider();
    this.altriServSlider();
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true
    })
  },
  menuInit: function(){
    $('.header__hamburger').on('click', function(){
      $('.header__hamburger, .header, .header__menu').toggleClass('opened');
    })

    // $('.header .menu-item-has-children > a').addClass('no-smoothState');
    
    // $('.header .menu-item-has-children').on('click', function(event){
    //   event.preventDefault();
    //   $(this).toggleClass('active');
    //   var sub = $(this).find('.sub-menu');
    //   if ($(window).outerWidth(true) > 991) {
    //     sub.toggleClass('active');
    //   } else {
    //     sub.slideToggle(200);
    //   }
    // })
  },
  langsMenu: function(){
    $('.header__langs').on('click', function(){
      $(this).find('.arrow').toggleClass('rot');
      $(this).find('.lang-sub-menu').fadeToggle(150);
    })
  },
  homeCasiStudio: function(){
    if (jQuery('.homeCasi__wrap').length) { 
      jQuery('.homeCasi__wrap').not('.slick-initialized').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: false,
        autoplaySpeed: 4500,
        lazyLoad: 'ondemand',
        speed: 800,
        infinite: true,
        responsive: [
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 2
              }
            },{
              breakpoint: 1300,
              settings: {
                slidesToShow: 1
              }
            }
          ]
      });
    }
  },
  homeCollaborazioni: function(){
    if (jQuery('.homeCollab__wrap').length) { 
      jQuery('.homeCollab__wrap').not('.slick-initialized').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: false,
        autoplaySpeed: 4500,
        lazyLoad: 'ondemand',
        speed: 800,
        infinite: true,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2
              }
            },{
              breakpoint: 640,
              settings: {
                slidesToShow: 1
              }
            }
          ]
      });
    }
  },
  homeMetodoSlider: function(){
    if (jQuery('.homeLavCar__wrap').length && $(window).outerWidth(true) < 768) { 
      jQuery('.homeLavCar__wrap').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: false,
        autoplaySpeed: 4500,
        lazyLoad: 'ondemand',
        speed: 800,
        infinite: true
      });
    }
  },
  serviziSlider: function(){
    if (jQuery('.setGal__gall').length) { 
      jQuery('.setGal__gall').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        autoplay: false,
        autoplaySpeed: 4500,
        lazyLoad: 'ondemand',
        speed: 800,
        infinite: true
      });
    }
  },
  altriServSlider: function(){
    if (jQuery('.altriCorsi__slider').length) { 
      jQuery('.altriCorsi__slider').not('.slick-initialized').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: false,
        autoplaySpeed: 4500,
        lazyLoad: 'ondemand',
        speed: 800,
        infinite: true,
        responsive: [{
          breakpoint: 1199,
          settings: {
            slidesToShow: 2
          }
        }]
      });
    }
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;


(function () {
  siteGlobal.initReady();
  
  $(window).on('load', function() {
    $('#preloader').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).on('scroll', function(){
    siteGlobal.initScroll();
  })

  $(window).on('resize', function(){
    siteGlobal.resize();
  })
}());